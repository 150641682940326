<template>
  <div>
    <div class="wEditor" ref="wEditor"></div>
  </div>
</template>
<script>
import uploader from "@/utils/uploader";
import GetSource from "./menus/get-source";
import wangEditor from "wangeditor";
export default {
  name: "ElEditor",
  props: {
    value: {
      type: String,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "default",
    },
    height: {
      type: Number,
      default: 300,
    },
  },
  watch: {
    value(newValue) {
      this.initContent(newValue);
    },
    disable(newVal) {
      this.handleDisable(newVal, this.editor);
    },
    height(newVal) {
      this.handleHeight(newVal, this.editor);
    },
  },
  data() {
    return {
      editor: null,
      doUpading: false,
      doInit: false,
    };
  },
  mounted() {
    // 初始化
    this.initEditor();
  },
  beforeDestroy() {
    this.editor.destroy();
    this.editor = null;
  },
  methods: {
    // 初始化编辑器
    initEditor() {
      const editor = new wangEditor(this.$refs.wEditor);
      editor.menus.extend("getSource", GetSource);
      if (this.type === "simple") {
        editor.config.menus = ["bold", "head", "fontSize", "italic", "underline", "strikeThrough", "list", "link"];
      } else if (this.type === "pure") {
        editor.config.menus = ["bold", "head", "fontSize", "italic", "underline", "strikeThrough", "list", "link", "indent", "justify", "quote"];
      } else {
        editor.config.menus = [
          "bold",
          "head",
          "fontSize",
          "italic",
          "underline",
          "strikeThrough",
          "indent",
          "foreColor",
          "backColor",
          "list",
          "justify",
          "quote",
          "image",
          "link",
          "table",
          "getSource",
        ];
      }
      editor.config.fontSizes = {
        "x-small": { name: "12px", value: "1" },
        small: { name: "14px", value: "2" },
        normal: { name: "16px", value: "3" },
        large: { name: "18px", value: "4" },
        "x-large": { name: "24px", value: "5" },
        "xx-large": { name: "32px", value: "6" },
        "xxx-large": { name: "48px", value: "7" },
      };
      // 判断是否禁用
      this.handleDisable(this.disable, editor);
      // 设置高度
      this.handleHeight(this.height, editor);

      editor.config.zIndex = 500;
      editor.config.showMenuTooltips = false;
      editor.config.focus = false;
      editor.config.placeholder = "";
      editor.config.showLinkImg = false;
      editor.config.uploadImgShowBase64 = true;
      editor.config.uploadImgMaxSize = 10 * 1024 * 1024;
      editor.config.customUploadImg = function (resultFiles, insertImgFn) {
        uploader
          .upload(resultFiles[0])
          .then(res => {
            const imgUrl = res.data.urls[0].url;
            insertImgFn(imgUrl);
          })
          .catch(err => {
            console.debug(err);
          });
      };
      editor.config.onchange = newHtml => {
        this.handleInput(newHtml);
      };
      editor.create();
      this.editor = editor;
    },
    // 禁用
    handleDisable(val, editor) {
      if (val) {
        editor.disable();
      } else {
        editor.enable();
      }
    },
    //设置高度
    handleHeight(val, editor) {
      editor.config.height = val;
    },
    // 监听输入
    handleInput(value) {
      if (!this.doInit) {
        console.debug("监听到输入");
        this.doUpading = true;
        this.$emit("input", value);
      }
      this.doInit = false;
    },
    // 初始化内容
    initContent(value) {
      // console.debug(this.doUpading, "新值:" + value || "空", "旧值:" + this.editor.txt.html() || "空");
      if (!this.doUpading) {
        this.editor.destroy();
        this.editor = null;
        this.initEditor();
        this.doInit = true;
        this.editor.txt.html(value);
      }
      this.doUpading = false;
    },
    // 插入html
    insertHtml(content) {
      this.editor.cmd.do("insertHTML", content);
    },
  },
};
</script>

<style lang="scss">
.wEditor {
  z-index: 1999 !important;
  & > div {
    border: 1px solid #ededed !important;
  }
  .w-e-text {
    table {
      tr {
        vertical-align: top;
      }
    }
  }
}
</style>
