<template>
  <el-dialog title="Make Supplemental" :close-on-click-modal="false" :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" autocomplete="off" label-width="285px">
      <el-form-item label="Amount" prop="amount">
        <el-input v-model="dataForm.amount" v-minusPoint2 placeholder="0.00">
          <template slot="prefix">S$</template>
        </el-input>
      </el-form-item>
      <el-form-item label="Third party fee" prop="thirdPartyFee">
        <el-input v-model="dataForm.thirdPartyFee" v-minusPoint2 placeholder="0.00">
          <template slot="prefix">S$</template>
        </el-input>
      </el-form-item>
      <el-form-item label="Description (For External Disclosure)" prop="remark">
        <el-input
          v-model="dataForm.remark"
          type="textarea"
          :rows="5"
          :autosize="{ minRows: 4 }"
          resize="none"
          placeholder="Please refer to the fee letter(s) signed on YYYY-MM-DD for the supplemental fees."
        ></el-input>
      </el-form-item>
      <el-form-item label="Other Fee" prop="otherFee">
        <el-input v-model="dataForm.otherFee" v-minusPoint2 placeholder="0.00">
          <template slot="prefix">S$</template>
        </el-input>
      </el-form-item>
      <el-form-item label="Note For Other Fee" prop="otherRemark">
        <el-select v-model="dataForm.otherRemark" placeholder="Note For Other Fee" filterable class="fluid-width text-left">
          <el-option v-for="item in otherFeeEnOptions" :key="item" :label="item" :value="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Fee Letter">
        <el-file-uploader v-model="dataForm.feeLetter"></el-file-uploader>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Cancel</el-button>
      <el-button type="primary" @click="dataFormSubmit()">Submit</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  props: {
    businessGenre: {
      type: String,
    },
    businessType: {
      type: String,
    },
    applymentNo: {
      type: String,
    },
  },
  data() {
    return {
      visible: false,
      dataForm: {
        amount: null,
        thirdPartyFee: null,
        remark: "",
        otherFee: null,
        otherRemark: "",
        feeLetter: undefined,
      },
      dataRule: {
        amount: [{ required: true, message: "Please Enter", trigger: "submit" }],
        thirdPartyFee: [{ required: true, message: "Please Enter", trigger: "submit" }],
        remark: [{ required: true, message: "Please Enter", trigger: "submit" }],
        otherFee: [{ required: true, message: "please enter", trigger: "submit" }],
        otherRemark: [{ required: true, message: "please select", trigger: "submit" }],
      },
      submitControl: true, // 防止重复提交
    };
  },
  computed: {
    ...mapState("constant", ["amendsStatusList", "otherFeeEnOptions"]),
  },
  methods: {
    ...mapActions("applyment", ["doMakeAmends"]),
    async init() {
      this.submitControl = true;
      this.visible = true;
      await this.$nextTick();
      this.$refs["dataForm"].resetFields();
      this.dataForm = {
        amount: null,
        thirdPartyFee: null,
        remark: "",
        otherFee: null,
        otherRemark: "",
        feeLetter: undefined,
      };
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid && this.submitControl) {
          this.submitControl = false;
          this.doMakeAmends({
            businessNo: this.applymentNo,
            businessGenre: this.businessGenre,
            businessType: this.businessType,
            ...this.dataForm,
          })
            .then(() => {
              this.$message({
                message: "success",
                type: "success",
                duration: 1500,
                onClose: () => {
                  this.visible = false;
                  // 更新发票列表
                  this.$emit("upInvoiceList");
                  // this.submitControl = true;
                },
              });
            })
            .catch(msg => {
              this.$message.error(msg);
              this.submitControl = true;
            });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.item-text {
  line-height: 40px;
  color: #41464b;
}
.price {
  color: #ff8653;
}
</style>
