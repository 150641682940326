<template>
  <el-dialog width="800px" title="Finish Review" :close-on-click-modal="false" :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" autocomplete="off" label-width="70px">
      <el-form-item label="Status" prop="status">
        <el-radio-group v-model="dataForm.status">
          <el-radio label="REJECTED">Rejected</el-radio>
          <el-radio label="PASSED">Passed</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="Remark" prop="auditRemark">
        <el-input
          v-model="dataForm.auditRemark"
          type="textarea"
          :rows="5"
          :autosize="{ minRows: 4 }"
          resize="none"
          placeholder="Remark"
          :oninput="isEn"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Cancel</el-button>
      <el-button type="primary" @click="dataFormSubmit">OK</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      visible: false,
      id: null,
      dataForm: {
        status: "PASSED",
        auditRemark: "",
      },
      submitControl: true,
      dataRule: {
        status: [{ required: true, message: "please select", trigger: "submit" }],
      },
    };
  },
  computed: {
    ...mapState("constant", ["supplementStatusList"]),
  },
  methods: {
    async init(row) {
      this.id = row.id;
      this.submitControl = true;
      this.visible = true;
      await this.$nextTick();
      this.$refs["dataForm"].resetFields();
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid && this.submitControl) {
          this.submitControl = false;
          this.$http.post(`/admin/businessSupplement/${this.id}/audit`, this.dataForm).then(({ code, msg }) => {
            if (code === 200) {
              this.visible = false;
              this.$emit("refreshStatus");
              // this.submitControl = true;
            } else {
              this.$message.error(msg);
              this.submitControl = true;
            }
          });
        }
      });
    },
  },
};
</script>
